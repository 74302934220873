import axios from 'axios';

const client = axios.create({
  baseURL: `${process.env.GATSBY_APP_UI_URL}/api/`,
});

export const getDiscountPageData = (type) => client.get(`discounts/${type}`).then(r => r.data);

export const loadComparePageData = (cat, type) => client.get(`pages/${cat}/${type}`).then(r => r.data);

export const getItemDetails = (id) =>
  client.get('/discounts/checkout', { params: { id }}).then(r => r.data);

export const getUpsells = () =>
  client.get('/pages/upsells').then(r => r.data);
