let store = {
  get: () => null
};

if (typeof window !== `undefined`) {
  const Basil = require('basil.js');
  store = new Basil({
    namespace: 'tic_cart',
    expireDays: 1
  });

}

export default store;
